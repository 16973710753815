<template>
  <div>
    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-3 col-12">
                <!-- <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.material_name"
                    placeholder="Saring Nama"
                  ></b-form-input>
                  <template #append>
                    <b-button squared @click="filterByPeriod" variant="success"
                      >Cari</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group> -->
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button
                        squared
                        @click="filterByPeriod"
                        variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="filterByPeriod"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <div class="row justify-content-start">
              <div class="col py-0">
                <!-- <b-button variant="danger" size="sm">
                  <i class="fas fa-file-pdf text-white px-0"></i>
                </b-button> -->
                <vue-excel-xlsx
                  :data="itemsExcel"
                  :columns="columns"
                  :filename="`Daftar Penduduk`"
                  :sheetname="`Daftar Penduduk`"
                >
                  <b-button
                    variant="primary"
                    class="ml-1 mt-1"
                    v-b-tooltip.hover
                    title="Cetak Excel"
                    size="sm"
                  >
                    <i class="fas fa-file-excel text-white px-0"></i>
                  </b-button>
                </vue-excel-xlsx>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(display_amount)="data">
                {{ data.item.display_amount }}
                
              </template>
              <template #cell(display_deposit)="data">
                {{ data.item.display_deposit }}
                
              </template>

            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "citizen_id_card_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "citizen_name",
          label: "Penerima BLT",
          sortable: true,
        },
        {
          key: "family_head_name",
          label: "Nama Kepala Keluarga",
          sortable: true,
        },
        {
          key: "display_amount",
          label: "Pengeluaran (Rp)",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal Penerimaan",
          sortable: true,
        },
      ],
      columns: [
        {
          field: "citizen_id_card_number",
          label: "NIK",
        },
        {
          field: "citizen_name",
          label: "Penerima BLT",
        },
        {
          field: "family_head_name",
          label: "Nama Kepala Keluarga",
        },
        {
          field: "display_amount",
          label: "Pengeluaran (Rp)",
        },
        {
          field: "display_date",
          label: "Tanggal Penerimaan",
        },
        {
          field: "additional",
          label: "Tanda Tangan / Cap Jempol",
        },
      ],
      // Table Items
      items: [],
      itemsExcel: [],
      
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      // user access
      btn: true,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&flow_type=1&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "expenses",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
      // export
      let responseExcel = await module.get("expenses", `?${filterParams}`);
      this.itemsExcel = responseExcel;
    },

    filterByName() {
      this.pagination();
    },

    filterByPeriod() {
      this.filter.start_date = moment(this.filter.start_date)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment(this.filter.end_date)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    // filter
    resetFilter() {
      this.filter.material_name = "";
      this.filter.start_date = moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("expenses/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1107") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pengeluaran" }]);
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>
